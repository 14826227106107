import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Container from '~components/Container';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton/RoundedLinkButton';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import RichText from '~components/RichText';
import Link from '~components/Link';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { getSubscribeButtonProps } from '~utils/buttontext';
import useConversionFramework from '~hooks/useConversionFramework';
import { getPlaceholderFormattedUrl } from '~utils/url-helper';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';

const IS_FREE_TRIAL_ENABLED = isFeatureEnabled(FEATURE_FLAGS.FREE_TRIAL);

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(to bottom, #F1F1F4 40%, #FFFFFF 40%)'
  },
  contentContainer: {
    height: '100%'
  },
  heroContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  topIconImg: {
    marginTop: 20,
    maxWidth: '90px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50px'
    }
  },
  topIconSubtitle: {
    color: '#000000',
    fontSize: 24,
    fontFamily: 'Inter',
    fontWeight: '600',
    marginTop: '18px',
    marginBottom: '0px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '4px',
      fontSize: '14px'
    }
  },
  heroImage: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: props => (props.hasMobileImage ? '70%' : '100%')
    }
  },
  heroTitle: {
    width: '100%',
    maxWidth: '973px',
    marginTop: '78px',
    marginBottom: '78px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '32px',
      marginBottom: '28px'
    },
    '& > *': {
      textAlign: 'center',
      color: '#000000',
      fontSize: 72,
      lineHeight: '124%',
      fontFamily: 'Inter',
      fontWeight: '700',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: '35px'
      }
    }
  },
  heroDescription: {
    maxWidth: 895,
    textAlign: 'center',
    '& > *': {
      fontFamily: 'Roboto',
      fontSize: '32px',
      fontWeight: 500,
      color: '#000000',
      lineHeight: '128%',
      margin: '80px 0px 52px 0px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
        margin: '27px 0px 40px 0px'
      }
    },
    '& > p:empty': {
      height: '14px'
    }
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& > a': {
        width: '100%',
        textAlign: 'center'
      }
    }
  },
  learnMoreText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000',
    textAlign: 'center',
    maxWidth: '390px',
    marginTop: 4,
    '& > a': {
      color: '#000000',
      textDecoration: 'underline',
      fontWeight: 600
    }
  }
}));

const ShowcaseHero = props => {
  const {
    title_rich,
    description,
    hero_image,
    mobile_friendly_hero_image,
    top_icon,
    top_icon_subtitle,
    cta_2_url,
    cta_2_text,
    bottom_shape_divider
  } = props;
  const hasMobileImage = !!mobile_friendly_hero_image?.url;
  const classes = useStyles({ hasMobileImage });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const heroImage = isMobile && hasMobileImage ? mobile_friendly_hero_image : hero_image;
  const { isMarketingSite } = useConversionFramework();
  const { jwtAvailable, marketingUser } = useUserContext().session;

  const {
    text: subscribeBtnText,
    url: subscribeBtnUrl,
    shouldTrackAnalytics: shouldCtaButtonTrackAnalytics,
    analyticsEvent: ctaAnalyticsEvent
  } = getSubscribeButtonProps(jwtAvailable, isMarketingSite, marketingUser);

  if (!IS_FREE_TRIAL_ENABLED) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Container className={classes.contentContainer}>
        <div className={classes.heroContainer}>
          {top_icon?.url && (
            <img className={classes.topIconImg} src={top_icon.url} alt={top_icon.alt} />
          )}
          {top_icon_subtitle && <h4 className={classes.topIconSubtitle}>{top_icon_subtitle}</h4>}
          <RichText
            html={title_rich?.html}
            externalClassName={classes.heroTitle}
            verticalSpacing={0}
          />
          {heroImage?.url && (
            <img src={heroImage.url} alt={heroImage.alt} className={classes.heroImage} />
          )}
          <RichText
            html={description && description.html}
            externalClassName={classes.heroDescription}
            verticalSpacing={0}
          />
          <div className={classes.buttonArea}>
            <RoundedLinkButton
              text={subscribeBtnText}
              to={subscribeBtnUrl}
              size="small"
              variant="blue"
              shouldTrackAnalytics={shouldCtaButtonTrackAnalytics}
              analyticsEvent={ctaAnalyticsEvent}
              analyticsTitle={title_rich?.text}
              analyticsSection="Showcase Hero"
            />
            {cta_2_url && (
              <RoundedLinkButton
                text={cta_2_text}
                to={getPlaceholderFormattedUrl(cta_2_url)}
                size="small"
                variant="black"
              />
            )}
          </div>
          <p className={classes.learnMoreText}>
            Not ready yet? Learn more about Aleph Beta and watch a few pieces of free content{' '}
            <Link to="#get-a-taste">here</Link>
          </p>
        </div>
      </Container>

      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

ShowcaseHero.propTypes = {
  title_rich: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string
  }),
  description: PropTypes.string,
  cta_2_text: PropTypes.string,
  cta_2_url: PropTypes.string,
  hero_image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  mobile_friendly_hero_image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  top_icon: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  top_icon_subtitle: PropTypes.string,
  bottom_shape_divider: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })
};

ShowcaseHero.defaultProps = {
  title_rich: null,
  description: '',
  hero_image: null,
  mobile_friendly_hero_image: null,
  top_icon: null,
  top_icon_subtitle: '',
  cta_2_text: '',
  cta_2_url: '',
  bottom_shape_divider: null
};

export default ShowcaseHero;
